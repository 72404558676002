import {Box, Button, CardActions} from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {SelectionType} from "./Choices/types";

export const MaterialCardActions: React.FC<{ onSelect?: () => void, isSelected: SelectionType, isFavorite?: boolean, onSetFavorite?: (value: boolean) => void }> = (props) => {
    const {onSelect, isSelected, onSetFavorite, isFavorite} = props;
    return <CardActions disableSpacing={false} sx={{
        display: 'flex',
        justifyContent: "center",
        flexDirection: 'column',
        height: "68px"
    }}>


        <Box sx={{flexDirection: 'row', width: '100%', justifyContent: 'space-between', display: "flex"}}>
            <Button sx={{width: "122px"}} size={"small"} variant={isSelected === "user" ? "contained" : "outlined"}
                    onClick={onSelect}>{isSelected === "user" ? "Selected" : "Select"}</Button>
            <Button onClick={() => onSetFavorite && onSetFavorite(!isFavorite)}
                    sx={{
                        minWidth: "0px",
                        color: "gray",
                        borderColor: "gray",
                        paddingLeft: "10px",
                        paddingRight: "10px"
                    }} variant={"outlined"}>
                {isFavorite === false && <FavoriteBorderIcon/>}
                {isFavorite === true && <FavoriteIcon/>}
            </Button>

        </Box>

    </CardActions>

}