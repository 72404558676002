import React from "react";
import {Card, CardHeader, CardMedia} from "@mui/material";
import FavoriteIcon from '@mui/icons-material/Favorite';

export interface IDefaultCardProps {
    title: string;
    subheader: string;
    onClick() : void;
    Icon: React.ReactElement;
}
export const DefaultCard: React.FC<IDefaultCardProps> = ({Icon, ...props}) => {
    return <Card onClick={props.onClick}
                 sx={{
                     display: "flex",
                     flexDirection: "column",
                     width: "250px",
                     height: "200px",
                     alignItems: "center",
                     justifyContent: "center",
                     cursor: "pointer"
                 }}>

        <CardMedia> { Icon }</CardMedia>
        <CardHeader sx={{textAlign: "center"}} title={props.title} subheader={props.subheader}></CardHeader>
    </Card>
}
