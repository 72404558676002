import React, {useEffect, useState} from 'react';
import './App.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Login from "./components/Login/Login";
import ConfiguratorLoader from "./components/Configurator/ConfiguratorLoader";
import {BrowserRouter} from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';
import {CustomerPortalAuthenticator} from "@bimaire/optionslib/build/api/customerportal-client";
import {useToken} from "@bimaire/react-lib";

//const customerPortalAuth = new CustomerPortalAuthenticator("http://localhost/Kova2102WebConfigurator");

function onLogin(username:string, password:string) {

}



export interface ICategoryConfig {
    name: string,
    optCategs?: string[];
    content?:string;
}
export interface IInspirationCategoryConfig {
    name:string;
    optSelMatch: string;
}
export interface IInspirationConfig {
    welcomeUrl: string;
    categories: IInspirationCategoryConfig[];
}
export interface IPricingConfig {
    show: boolean;
    ranges?: IPricingRange[];
}
export interface IPricingRange {
    name: string;
    min: number;
    max: number; 
}
export interface IConfig {
    kovaApiEndpoint: string;
    packages?: { filter: string[]};
    pricing?: IPricingConfig;
    categories: ICategoryConfig[];
    inspiration: IInspirationConfig;

}
var queryClient = new QueryClient();

async function loadConfig() {
    const configReq = fetch('/config');
    const siteConfigReq = fetch('/siteconfig.json');

    const configJson = await (await configReq).json();
    const siteConfig = await (await siteConfigReq).json();
    return { ...configJson, "kovaApiEndpoint": siteConfig["KovaEndpointApi"]};
}
function App() {

    const [config, setConfig] = useState<null|IConfig>();

    useEffect(() => {
        loadConfig().then(configResJson => setConfig(configResJson as IConfig));
    }, [])
    if (config === null || config === undefined) return <div>Loading...</div>

    return <QueryClientProvider client={queryClient}><AppWithConfig config={config} /></QueryClientProvider>;
}
const AppWithConfig: React.FC<{config:IConfig}> = props => {
const { config } = props;
    const [token, setToken] = useToken();
    const logout = () => setToken(null);

    const customerPortalAuth = new CustomerPortalAuthenticator(config.kovaApiEndpoint);
    const onLoginWithCustomerPortalToken = (customerPortalToken:string) => {
        customerPortalAuth.loginWithToken(customerPortalToken, true).then(tk => {
            setToken(tk);
            window.history.replaceState("", document.title, "/");


        });
    }

    const onLogin = (username:string, password: string) => {
        customerPortalAuth.login(username, password).then(token => {
            setToken(token);
            window.history.replaceState("", document.title, "/");
        })

    }
  return (
      <BrowserRouter>
    <div className="App">
        { token === null && <Login onLogin={onLogin} onLoginWithCustomerPortalToken={onLoginWithCustomerPortalToken}></Login> }
        { token !== null && <ConfiguratorLoader kovaApiToken={token.csrfToken}
                                                onLogout={logout}
                                                config={config}
                                                token={token.customerPortalToken}
                                                webConfiguratorEndpoint={token.customerPortalUrl} />}
    </div>
      </BrowserRouter>
  );
}

export default App;
