import {Badge} from "../../../Summary/Summary";

import {IChoiceSkuDetails, ISkuElement} from "@bimaire/optionslib/build/api/skuworld-client";
import {MaterialCardStandard, MaterialCardImage, MaterialCardImageContainer} from "@bimaire/react-lib";
import {truncateName} from "../utils";
import { Tooltip } from "@mui/material";

export interface IChoiceCardProps {
    subheader: string;
    /**
     * true if we want to show the "included" text for price 0 items.
     */
    showIncluded: boolean;
    name: string;
    skuDetails: IChoiceSkuDetails | null;
    onShowSkuDetails: (element: ISkuElement) => void;
    actions: React.ReactElement;
}

/**
 * Presentational component for showing a single "card" for a option choice.
 * @param props
 * @constructor
 */
export const ChoiceCard: React.FC<IChoiceCardProps> = (props) => {

    const {
        subheader,
        skuDetails,
        name,
        onShowSkuDetails,
        showIncluded,
        actions
    } = props;


    return <MaterialCardStandard
        name={truncateName(name)}
        subheader={subheader}
        actions={actions}
    >
        <Tooltip title={name}>
            <div style={{position: 'relative'}}><MaterialCardImageContainer>
                {skuDetails && skuDetails.skus.length > 0 &&
                
                    <MaterialCardImage imageUrl={skuDetails.skus[0].mainImage}
                                onClick={() => onShowSkuDetails(skuDetails.skus[0])}/>
                
                }
                {skuDetails === null && <MaterialCardImage imageUrl={"/notavailable.png"}/>}
                {showIncluded && <Badge>Included</Badge>}

            </MaterialCardImageContainer>
            </div>
        </Tooltip>

    </MaterialCardStandard>
}