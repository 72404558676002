import {IConfiguratorProxy, IOptionItem, IOptionItemAvailableChoice, IOptionItemChoice} from "@bimaire/optionslib";
import {ICategoryConfig, IPricingConfig} from "../../App";
import styled from "@emotion/styled";
import groupBy from "@bimaire/optionslib/build/utils/groupBy";
import {ChoiceCard} from "../Categories/Choices/Cards/ChoiceCard";
import {MUIOptItemCardMultipleHeader} from "../Categories/Choices/MUIOptItemCardMultipleHeader";
import {MaterialCardActionsBinary} from "../Categories/Choices/Cards/MaterialCardActionsBinary";
import {MaterialCardRow} from "../Categories/Choices/MaterialCardRow";
import {useState} from "react";
import {priceToString} from "../Summary/Summary";
import {SkuLoader, SkuDetailsDialog} from "@bimaire/react-lib";
import {IImageSource} from "@bimaire/optionslib/build/api/customerportal-client";
import {WishlistManager} from "@bimaire/optionslib/build/api/WishlistManager";
import {IChoiceSkuDetails, ISkuElement } from "@bimaire/optionslib/build/api/skuworld-client";
import { isChoiceAvailable } from "../Categories/MUICategoryOptItem";
import { useParams } from "react-router-dom";
import { getItemsGroupedByCategory } from "../Categories/CategoryListNavigation";


export interface IAccessoriesProps {
    configurator: IConfiguratorProxy;
    imageSource: IImageSource;
    priceSource: (choice: IOptionItemAvailableChoice) => number|null;
    category: ICategoryConfig;
    onSelect: (choice: IOptionItemChoice) => void;
    wishlistManager: WishlistManager;
    pricing?: IPricingConfig;
}

const AccessoryDiv = styled.div`
display: flex;
  padding:16px;
  flex-wrap: wrap;
`
interface IAccessoryItemProps {
    isSelected: boolean,
    onSelect: (selected:boolean) => void,
    name:string;
    skuDetails: IChoiceSkuDetails|null;
    price: number|null;
    onShowSkuDetails: (sku:ISkuElement) => void;
    pricing?: IPricingConfig;
}
const AccessoryItem: React.FC<IAccessoryItemProps> = (props) => {
    const { isSelected, onSelect, price, name,skuDetails, onShowSkuDetails, pricing } = props;
    const actions = <MaterialCardActionsBinary onSelect={onSelect} isSelected={isSelected} />;

    return <ChoiceCard subheader={priceToString(price, pricing)} showIncluded={!!pricing?.show && price === 0} name={name} skuDetails={skuDetails} onShowSkuDetails={onShowSkuDetails} actions={actions} />

}

const Accessories: React.FC<IAccessoriesProps> = (props) => {

    const { configurator, priceSource, onSelect, wishlistManager, category, pricing } = props;
    const [shownSkuDetails, setShownSkuDetails] = useState<ISkuElement | null>();

    const {optCateg} = useParams();

    const items = configurator.getOptionItems();
    const allChoices = configurator.getAllChoices();

    const optCategs = category.optCategs;
    if (optCategs === undefined) throw new Error("optCategs not defined");
    const visibleItems = items.filter(itm => optCategs.includes(
            itm.categories["Category"]) && itm.categories["DesignCenter"] === "yes"
    );

    const optCategGroups = getItemsGroupedByCategory(visibleItems);
    const categoryItems = optCategGroups[optCateg ?? ''];

    const accessoryItems = categoryItems.filter(item => item.type === "Binary");
    const accessoryChoices = allChoices.filter(ch => accessoryItems.includes(ch.items[0].item));
    const avail = configurator.getOptionAvailability(accessoryChoices);

    const visibleChoices = accessoryChoices.filter(ch =>
        isChoiceAvailable(avail.availability, ch) && 
        (ch.items.length == 1 || priceSource(ch) !== null) //don't check price for unselect
      );

    const selectChoices = visibleChoices.filter(item => item.items.length > 1);
    const unselectChoices = visibleChoices.filter(item => item.items.length == 1)

    return <AccessoryDiv>
        {shownSkuDetails && <SkuDetailsDialog onClose={() => setShownSkuDetails(null)} sku={shownSkuDetails} /> }

        <div className={"items"}>
            <MUIOptItemCardMultipleHeader name={optCateg + " - More Options"} backgroundColor={"#fff"} />
            <MaterialCardRow>

            { selectChoices.map(ch => {
                const unselectChoice  = unselectChoices.find(u => u.items[0].item.id === ch.items[0].item.id)!;
                const itm = ch.items[0].item;
                const price = priceSource(ch);
                return <SkuLoader choice={ch} key={itm.id}> 
                    {({skuDetails}) => <AccessoryItem
                        onShowSkuDetails={(sku) => setShownSkuDetails(sku)}
                    skuDetails={skuDetails}
                    price={price}
                    pricing={pricing}
                    name={itm.name}
                    onSelect={(isSelected) => onSelect({choice: isSelected ? ch : unselectChoice, price: null, quantity: 1})}
                    isSelected={wishlistManager.isSelected(itm)}
                /> }
                    </SkuLoader>;

            })
            }
            </MaterialCardRow>

        </div>
    </AccessoryDiv>
}

export default Accessories;