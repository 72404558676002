import {IInspirationCategoryConfig, IInspirationConfig} from "../../App";
import {useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import { ChoiceCard } from "../Categories/Choices/Cards/ChoiceCard";

import {ReactElement, useState} from "react";
import {useSkuMap, useSkuWorldSource, SkuDetailsDialog} from "@bimaire/react-lib";
import {
    IInspirationModelOption,
    IInspirationModelResponse
} from "@bimaire/optionslib-kova/build/api/dcportal-apiclient";
import {IChoiceSkuDetails, ISkuElement} from "@bimaire/optionslib/build/api/skuworld-client";

export interface IInspirationModelDetailsProps {
    model: IInspirationModelResponse;
    category: IInspirationCategoryConfig;
}

export interface IInspirationModelDetailsRouteProps {
    inspirationConfig: IInspirationConfig;
    inspirationData: IInspirationModelResponse[];
}
export const InspirationModelDetailsRoute: React.FC<IInspirationModelDetailsRouteProps> = (props) => {
    const {categoryId, modelId } = useParams();

    if (categoryId === undefined || modelId === undefined) throw new Error();
    const category = props.inspirationConfig.categories[parseInt(categoryId)];
    const model = props.inspirationData.find(model => model.id === modelId);

    if (model === undefined || category === undefined) throw new Error();

    return <InspirationModelDetails model={model} category={category} />

}

function filterOptsForCategory(model: IInspirationModelResponse, category: IInspirationCategoryConfig) {
    return model.options.filter(mo => mo.optSelID.match(category.optSelMatch));

}

function getSkuOptIdForInspirationOption(option: IInspirationModelOption) {
    return option.optValID + option.style + option.color;
}
const InspirationSkuLoader: React.FC<{option: IInspirationModelOption, children: (props: { skuDetails: IChoiceSkuDetails | null }) => ReactElement<any, any> | null }> = (props) => {

    const {option} = props;
    const skuSource = useSkuWorldSource();

    const skuOptId = getSkuOptIdForInspirationOption(option);
    const skuMapId = skuSource.getSkuMapIdWithOptId(skuOptId);
    const skuDetails = useSkuMap(skuMapId);

    if (skuDetails === null) return null;
    if (skuDetails === undefined) {
        return <div title={skuMapId}>{props.children({skuDetails: null})}</div>
    }
    return props.children({skuDetails});


}
function getSubheader(opt: IInspirationModelOption) {
    const comps = [opt.optValName, opt.style, opt.color].filter(elem => elem !== "").join(" / ");
    return comps;
}
const InspirationModelDetails: React.FC<IInspirationModelDetailsProps> = (props) => {
    const { model, category } = props;

    const skuSource = useSkuWorldSource();
    const [shownSkuDetails, setShownSkuDetails] = useState<ISkuElement|null>(null);
    const matchingOptions = filterOptsForCategory(model, category);

    const matchingOptionsWithSku = matchingOptions.filter(mo => skuSource.getSkuMapIdWithOptId(getSkuOptIdForInspirationOption(mo)) !== undefined);


    return <Box sx={{display: 'flex', boxSizing: 'border-box', height: '100%'}} pt={4} pl={3}>
        { shownSkuDetails && <SkuDetailsDialog onClose={() => setShownSkuDetails(null)} sku={shownSkuDetails} /> }
<Box sx={{flex: '1 1', display: 'flex', flexWrap: 'wrap', gap: '24px', overflow: 'scroll-y'}}>

        { matchingOptionsWithSku.map(opt =>
        <InspirationSkuLoader option={opt}>{
            ({skuDetails}) => <ChoiceCard
                actions={<></>}
                onShowSkuDetails={(skuDetails) => setShownSkuDetails(skuDetails)}
                name={opt.optSelName}
                skuDetails={skuDetails}
                subheader={getSubheader(opt)}
                showIncluded={false}

            />
        }
        </InspirationSkuLoader>
            ) }
        </Box>

        <Box sx={{flex: '0 0 756px'}}>
            <iframe style={{width: '100%', height: '100%'}} src={"https://youriguide.com/The_Waverleigh?page=gallery"} />

        </Box>
    </Box>
}