import {Box} from "@mui/material";
import {IOptionItemAvailableChoice} from "@bimaire/optionslib";
import groupBy from "@bimaire/optionslib/build/utils/groupBy";
import {MUISelectionChoicesPackages} from "./MUISelectionChoicesPackage";
import {MUISelectionNormalItemChoices} from "./MUISelectionNormalItemChoices";
import {IMUISelectionChoicesProps} from "./types";
import {IPackageConfig} from "../../Configurator/ConfiguratorLoader";


/**
 * This component is used to render the choices using the appropriate UI components depending on the choice type.
 * @param props
 * @constructor
 */
export const MUISelectionChoices: React.FC<IMUISelectionChoicesProps & { packageConfig: IPackageConfig }> = (props) => {
    const { choices, packageConfig, ...restProps } = props;
    // normal items = not packages. packageItems = packages.
    const { normalItems, packageItems } = groupBy(choices,
            ch => packageConfig.isPackage(ch) ? "packageItems" : "normalItems") as Record<"packageItems" | "normalItems", IOptionItemAvailableChoice[]>;

    return (<Box sx={{display: 'flex', flexDirection: 'column', gap:'24px', flexGrow: '1'}}>
        { packageItems?.length > 0 && <MUISelectionChoicesPackages {...restProps} choices={packageItems} /> }
        { normalItems?.length > 0 && <MUISelectionNormalItemChoices {...restProps} choices={normalItems} /> }
    </Box>)
}
