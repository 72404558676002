import {MainLayout} from "../Configurator/MainLayout";
import {Box, Button, Card, CardActions, CardContent, Typography} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";
import {ICategoryConfig, IConfig} from "../../App";
import {IConfiguratorProxy, IOptionItemAvailableChoice} from "@bimaire/optionslib";
import {DefaultCard} from "./DashboardCard";
import {LinearProgressWithLabel} from "./LinearProgressWithLabel";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {WishlistManager} from "@bimaire/optionslib/build/api/WishlistManager";
import groupBy from "@bimaire/optionslib/build/utils/groupBy";
import { isSelectionItemAvailable } from "../Categories/CategoryListNavigation";


const PlaceholderCard: React.FC = (props) => {

    return <DefaultCard
    Icon={ <img src={"dashboardcategory.svg"}/>}
        title={"Title"} subheader={"Short description of feature goes here."} onClick={() => {}} />
}

const StepProgress: React.FC<{step:string, value:number}> = (props) => {
    return <Box><Typography variant={"subtitle2"} >{props.step}</Typography>
    <LinearProgressWithLabel value={props.value}></LinearProgressWithLabel></Box>
}

export interface IDashboardProps {
    config: IConfig;
    configurator: IConfiguratorProxy;
    wishlistManager: WishlistManager;
}

export interface IStepWithProgress {
    name: string;
    items: number;
    completedItems: number;
}

export function getStepsWithProgress(categories: ICategoryConfig[], configurator: IConfiguratorProxy, wishlistManager: WishlistManager) {
    const items = configurator.getOptionItems();
    const allChoices = configurator.getAllChoices();
    const avail = configurator.getOptionAvailability(allChoices);
    const itemsBySelectionId = groupBy(allChoices, ch => ch.items[0].item.id);

    const priceSource = (choice: IOptionItemAvailableChoice) => {
        return configurator.getPriceDeltaForChoice({ choice: choice, quantity: 1, price: null });
    }

    return categories.map(cat => {
        const optCategs = cat.optCategs;
        if (optCategs === undefined) throw new Error('Invalid argument - category has no optCategs!');
        const itemsInCategory = items.filter(item => optCategs.find(oc => oc === item.categories["Category"]) !== undefined && 
                                                item.categories["DesignCenter"] === "yes" &&
                                                (item.type === "Attribute" || item.type === "Binary"));
        const visibleItems = itemsInCategory.filter(itm => isSelectionItemAvailable(itemsBySelectionId[itm.id], avail, priceSource));

        const numItems = visibleItems.length;
        const complItems = visibleItems.filter(itm => wishlistManager.isSelected(itm)).length;
        return { name: cat.name, items: numItems, completedItems: complItems};

    })
}
const Dashboard: React.FC<IDashboardProps> = (props) => {

    const {configurator, config, wishlistManager} = props;

    const navigate = useNavigate();
    const launchConfigurator = () => {
        navigate('configurator/0');
    }
    const optionCategories = config.categories.filter(c => c.optCategs !== undefined);

    const stepsWithProgress = getStepsWithProgress(optionCategories, configurator, wishlistManager);
    return <MainLayout>
        <Box mt={4} sx={{marginLeft: "50px", flexDirection: "column", display: "flex", width: "100%"}}>
            <Typography style={{fontWeight: 700}} variant={"h4"}>Welcome,</Typography>
            <Typography>Let's make your dream home a reality</Typography>


            <Box sx={{flexDirection: "row", display: "flex"}} mt={4}>
                <Box sx={{flexGrow: 1, gap: "32px", maxWidth: "900px", display: "flex", flexWrap: "wrap"}}>

                    <DefaultCard
                        Icon={<img src={"dashboardcategory.svg"}/>}
                        title={"Selections"} subheader={"Online design center"}
                                   onClick={launchConfigurator}/>
                    <DefaultCard
                        Icon={<FavoriteIcon sx={{width: '40px', height: '40px', color:'#2196F3', opacity: '0.5'}} />}
                        title={"My Favorites"}
                        subheader={"View your favorites"}
                        onClick={() => navigate('/configurator/-1')}/>
                    { /*
                    <DefaultCard
                        Icon={ <img src={"dashboardcategory.svg"}/>}
                        title={"Inspiration"} subheader={"Inspiration"} onClick={() => navigate('/inspiration/')} />
                    <PlaceholderCard/>
                    <PlaceholderCard/>
                    <PlaceholderCard/>
                    <PlaceholderCard/>
                    <PlaceholderCard/>

                    <PlaceholderCard/>
                    */ }
                </Box>
                <Card sx={{width: "500px", display: "flex", flexDirection: "column"}}>

                    <CardContent sx={{height: "100%"}}>
                        <Typography variant={"subtitle1"}>Progress:</Typography>

                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '32px', flexGrow: 1}}>
                            {stepsWithProgress.map(sp => sp.items > 0 && <StepProgress step={sp.name}
                                                                       value={(sp.completedItems / sp.items) * 100}/>)}
                        </Box>
                    </CardContent>
                    <CardActions sx={{alignSelf: "center", marginBottom: "24px"}}>
                        <Button variant={"contained"} onClick={launchConfigurator}>Continue</Button>
                    </CardActions>
                </Card>
            </Box>
        </Box>

    </MainLayout>
}
export default Dashboard;