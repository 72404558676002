import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {ConfigurationState} from "OptConfigurator";
import {IConfiguratorProps} from "./Configurator";
import CategoryList from "../Categories/CategoryList";

import HomeIcon from '@mui/icons-material/Home';
import {useTheme} from "@mui/material";

import {IConfig} from "../../App";
import {MainLayout} from "./MainLayout";
import {Navigate, Route, Routes, useNavigate, useParams} from "react-router-dom";
import Dashboard, { getStepsWithProgress } from "../Dashboard/Dashboard";
import Summary from "../Summary/Summary";
import {useWishlist} from "./useWishlist";

import {IConfiguratorProxy, IOptionItemAvailableChoice, IOptionItemChoice} from "@bimaire/optionslib";
import InspirationMain from "../Inspiration/InspirationMain";
import MainMenu, {IMainMenuItem, StepsDiv} from "../MainMenu/MainMenu";
import {SkuworldProvider} from "@bimaire/react-lib";
import WishlistClient, {applyWishlistToConfigurator, findWishlist} from "@bimaire/optionslib/build/api/wishlist-client";
import {WishlistManager} from "@bimaire/optionslib/build/api/WishlistManager";
import DCPortalApiClient from "@bimaire/optionslib-kova/build/api/dcportal-apiclient";
import {SkuWorldClient, SkuWorldSource} from "@bimaire/optionslib/build/api/skuworld-client";
import {KovaConfigurator} from "@bimaire/optionslib-kova";


interface IPackageConfigSpec {
    filter: string[];
}
class PackageConfig implements IPackageConfig {
    private readonly spec: IPackageConfigSpec;
    constructor(config: IConfig) {
        this.spec = config.packages ?? { filter: [] };
    }
    isPackage(choice: IOptionItemAvailableChoice) {

        for (const f of this.spec.filter) {
            if (choice.items.length < 2) {
                return false;
            }
            if (choice.items[1].item.id.match(f)) {
                return true;
            }
        }
        return false;
    }
}
export interface ICategory {
    label:string;
    optCategs: string[];
}
export interface IPackageConfig {
    isPackage(optValId:IOptionItemAvailableChoice): boolean;
}
const ConfiguratorMain: React.FC<IConfiguratorProps> = (props) => {

    const { configurator, config, customerPortalClient, wishlistManager } = props;
    const [currentConfigurator, setCurrentConfigurator] = useState(configurator);

    const categories = config.categories;
    const navParms =  useParams();
    const step = parseInt(navParms.step ?? "0");

    const navigate = useNavigate();
    (window as any).configurator = configurator;

    const availableChoices = configurator.getAllChoices();

    const setStep = (stepNum: number) => navigate(`/configurator/${stepNum}`);
    //const [step, setStep] = useState<number>(0);
    const onSelect = (choice: IOptionItemChoice) => {
        const isBinaryDeselect = choice.choice.items[0].item.type === "Binary" && choice.choice.items[1] === undefined;
        const wishlistType = isBinaryDeselect || findWishlist(wishlistManager.wishlistOptions, "Choice", choice.choice) ? "None" : "Choice"
        //unselect if wish list opt is selected

        wishlistManager.updateWishlistOption(choice.choice, wishlistType);

        var newChoice = choice;
        //when unselecting, select the first choice in menu
        if (!isBinaryDeselect && wishlistType === "None") {
            const availChoice = availableChoices.find(ch => ch.items[0].item.id === choice.choice.items[0].item.id);
            if (availChoice) {
                newChoice = {choice: availChoice, quantity: 1, price: null};
            }
        }

        setCurrentConfigurator(old => {
            const updatedConfigurator = old.applyChoice(newChoice);
            return updatedConfigurator;
        });
    }
    const theme = useTheme();

    const optionCategories = categories.filter(c => c.optCategs !== undefined);
    const stepsWithProgress = getStepsWithProgress(optionCategories, configurator, wishlistManager);

    //filter out categories without any visible optsels
    const categoriesWithItems = categories.filter(c => c.optCategs === undefined || stepsWithProgress.find(s => s.name === c.name)?.items);
    const items: IMainMenuItem[] = categoriesWithItems.map((cat, idx) => ({name: cat.name, href: `/configurator/${idx}`}) );
    items.push({name: "Summary", href: "/configurator/-1"});

    const packageConfig = new PackageConfig(config);
    return <MainLayout appBarContent={<MainMenu items={items} progress={stepsWithProgress} />}>
        <div className={"selection"}>
            { step !== -1 && <CategoryList category={categories[step]} packageConfig={packageConfig} pricing={config.pricing} wishlistManager={wishlistManager} configurator={currentConfigurator} onSelect={onSelect} imageSource={customerPortalClient}/> }
            { step === -1 && <Summary configurator={currentConfigurator} wishlistManager={wishlistManager} config={config} /> }
        </div>
    </MainLayout>

}


export interface IConfiguratorLoaderProps {
    config: IConfig;
    kovaApiToken:string;
    onLogout: () => void;
    token:string,
    webConfiguratorEndpoint:string;
}


const ConfiguratorLoader: React.FC<IConfiguratorLoaderProps> = (props) => {
    const {config, onLogout} = props;

    const {dcPortalApiClient, skuWorldClient, wishlistClient} = useMemo(() => {
        const dcPortalApiClient = new DCPortalApiClient(props.config.kovaApiEndpoint, "", props.kovaApiToken);
        const skuWorldClient = new SkuWorldClient(props.config.kovaApiEndpoint, props.kovaApiToken);
        const wishlistClient = new WishlistClient(props.config.kovaApiEndpoint, props.kovaApiToken);
        return {dcPortalApiClient, skuWorldClient, wishlistClient}
    }, [props.config.kovaApiEndpoint, props.kovaApiToken]);

    const [initialState, setInitialState] = useState<ConfigurationState | null>(null);
    const [skuWorldSource, setSkuWorldSource] = useState<SkuWorldSource | null>(null);
    const [wishlistOptions, setWishlistOptions, serverWishlistOptions] = useWishlist(wishlistClient);
    useEffect(() => {
        dcPortalApiClient.getConfiguratorState().then(state => {
            console.log(state);
            setInitialState(state);
        }).catch(err => {
            onLogout();
        })
    }, [props.token, onLogout])

    useEffect(() => {
        skuWorldClient.getSkuSource().then(skuSource => {
            setSkuWorldSource(skuSource);
        });
    }, []);

    const wishlistManager = useMemo(() => {
            if (wishlistOptions === null) return null;
            return new WishlistManager(wishlistOptions, setWishlistOptions);
        },
        [wishlistOptions, setWishlistOptions]);

    const confProxy = useMemo(() => {
        if (serverWishlistOptions === null || initialState === null) return null;
        const kovaConfigurator = new KovaConfigurator(null);
        let conf = kovaConfigurator.getConfiguratorProxy(initialState) as IConfiguratorProxy;

        conf = applyWishlistToConfigurator(serverWishlistOptions, conf);

        return conf;

    }, [serverWishlistOptions, initialState]);

    if (confProxy === null || skuWorldSource === null || wishlistManager === null) {
        return <div>Loading</div>
    }


    return <div>
        <SkuworldProvider skuworldSource={skuWorldSource}>
            <Routes>
                <Route path={"/cplogin"} element={<Navigate to={"/"} replace={true}/>} />
                <Route path={"configurator/:step/*"} element={<ConfiguratorMain
                    wishlistManager={wishlistManager}
                    configurator={confProxy}
                    config={config}
                    customerPortalClient={dcPortalApiClient}/>}
                />
                <Route index element={<Dashboard config={config} configurator={confProxy}
                                                 wishlistManager={wishlistManager}/>}/>
                <Route path={"inspiration/*"} element={<InspirationMain config={config} configurator={confProxy}
                                                                      wishlistManager={wishlistManager}
                                                                      customerPortalClient={dcPortalApiClient}/>}/>

            </Routes>

        </SkuworldProvider>
        {/*  <Configurator configurator={confProxy} customerPortalClient={dcPortalApiClient}></Configurator>  */}
    </div>
}


export default ConfiguratorLoader;