import {Box, Card} from "@mui/material";
import {MUIOptItemCardSingleHeader} from "./MUIOptItemCardSingleHeader";
import {getNameForChoice} from "./utils";
import {IMUISelectionChoiceProps, IMUISelectionChoicesProps} from "./types";
import {SkuLoader, MaterialCardImage, MaterialCardImageContainer} from "@bimaire/react-lib";
import {findWishlist} from "@bimaire/optionslib/build/api/wishlist-client";

const MUISelectionChoicesPackage: React.FC<IMUISelectionChoiceProps> = (props) => {
    const {choice, priceSource, wishlistFavorites, currentChoice, onShowSkuDetails, onSetFavorite, onSelect, pricing} = props;

    const price = priceSource(choice);
    const isFavorite = findWishlist(wishlistFavorites, "Favorite", choice);
    const isUserChoice = findWishlist(wishlistFavorites, "Choice", choice);
    return <SkuLoader key={choice.items.map(itm => itm.item.id).join(",")} choice={choice}>
        {({skuDetails}) => <Card sx={{width: "100%"}}><MUIOptItemCardSingleHeader
            price={price}
            pricing={pricing}
            name={getNameForChoice(choice)}
            isFavorite={isFavorite}
            isSelected={currentChoice?.choice === choice}
            onSelect={() => onSelect({price: null, quantity: 1, choice: choice})}
            onSetFavorite={(val) => onSetFavorite(choice, val)}
            key={choice.items.map(itm => itm.item.id).join("/")}
        />
            <Box sx={{p: 2, pt: 1}}>
                <MaterialCardImageContainer>
                    {skuDetails && skuDetails.skus.map(sd => <MaterialCardImage
                        key={sd.id}
                        imageUrl={sd.mainImage}
                        onClick={() => onShowSkuDetails(sd)}/>)}
                </MaterialCardImageContainer>
            </Box>
        </Card>
        }
    </SkuLoader>;

}
/**
 * This component is used to render choices that are "packages", i.e. contain multiple SKUs.
 * @param props
 * @constructor
 */
export const MUISelectionChoicesPackages: React.FC<IMUISelectionChoicesProps> = (props) => {
    const {choices, ...restProps} = props;
    return <>{choices.map(availableChoice => <MUISelectionChoicesPackage 
        key={availableChoice.items.map(itm => itm.item.id).join(",")}
        choice={availableChoice} {...restProps} />)}</>;
}