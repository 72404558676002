import {useEffect, useState} from "react";
import {isChoiceAvailable} from "./MUICategoryOptItem";
import {Accordion, AccordionDetails, AccordionSummary, MenuItem, MenuList, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckIcon from '@mui/icons-material/Check';
import {NavLink, useNavigate} from "react-router-dom";
import styled from "@emotion/styled";
import {ICategoryProps} from "./CategoryList";
import groupBy from "@bimaire/optionslib/build/utils/groupBy";
import {IOptionChoiceAvailabilityMap, IOptionItem, IOptionItemAvailableChoice} from "@bimaire/optionslib";

const CategorySection = styled.section`
  background-color: rgba(61, 107, 119, 0.16);
  padding: 24px;
  overflow-y: auto;
  flex: 0 0 304px;

  .selected {
    color: green;
    text-decoration: underline;
  }

  width: 304px;

`

export function getItemsGroupedByCategory(items: IOptionItem[]) {
    const optCategGroups = new Object(null) as Record<string, IOptionItem[]>;
    for (const itm of items) {
        const catgroup = itm.categories["CatGroup"];
        const currentItems = optCategGroups[catgroup] ?? [];
        currentItems.push(itm);
        optCategGroups[catgroup] = currentItems;
    }
    return optCategGroups;
}

export function isSelectionItemAvailable(allChoicesForItem: IOptionItemAvailableChoice[], availability: IOptionChoiceAvailabilityMap, priceSource: (choice: IOptionItemAvailableChoice) => number | null) {
    for (const ch of allChoicesForItem) {
        //don't check price for binary unselect option
        if (isChoiceAvailable(availability.availability, ch) && ch.items.length > 1 && priceSource(ch) !== null) {
            return true;
        }
    }
    return false;
}

interface ICategoryListCategory {
    optCateg:string;
    isExpanded: boolean; // openCategoryToUse === optCateg
    onExpand: (isExpanded: boolean) => void;
    items: IOptionItem[];
    isItemComplete: (item:IOptionItem) => boolean;
    complete: boolean; // optCategGroups[optCateg].find(itm => !wishlistManager.isSelected(itm)) !== undefined

}

/**
 * This component used to navigate between OptSels in a category.
 * @param props
 * @constructor
 */
const CategoryListCategory: React.FC<ICategoryListCategory> = (props) => {
    const { optCateg, isExpanded, onExpand, items, complete, isItemComplete } = props;

    const visibleAttributeItems = items.filter(itm => itm.type === "Attribute");
    const binaryItems = items.filter(itm => itm.type === "Binary");

    const allItemsCompleted = (items: IOptionItem[]) => {
        for (const itm of items) {
            if (!isItemComplete(itm)) {
                return false;
            }
        }
        return true;
    };

    return <Accordion disableGutters={true}
               elevation={0}
               key={optCateg}
               expanded={isExpanded}
               onChange={(ev, isExpanded) => onExpand(isExpanded)}
               sx={{backgroundColor: "transparent", "&:before": {display: "none"}}}>
        <AccordionSummary sx={{paddingLeft: 0}} expandIcon={<ExpandMoreIcon/>}>

            {complete ?
                <CircleOutlinedIcon sx={{color: "rgba(0, 0, 0, 0.6)"}}/> :
                <CheckCircleOutlineIcon sx={{color: "rgba(0, 0, 0, 0.6)"}}></CheckCircleOutlineIcon>

            }
            <Typography variant={"subtitle1"} ml={2} color={"rgba(0, 0, 0, 0.6)"}>{optCateg}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <MenuList>
                {visibleAttributeItems.map(itm =>
                    <NavLink key={itm.id}
                             to={encodeURIComponent(optCateg) + "/" + encodeURIComponent(itm.id)}
                             className={({isActive}) => isActive ? "active" : ""}>
                        <MenuItem sx={{paddingTop: "8px", paddingBottom: "8px"}}>
                            {isItemComplete(itm) && <CheckIcon sx={{color: "rgba(0, 0, 0, 0.6)"}}/>}
                            <Typography ml={3}
                                        variant={"subtitle1"} whiteSpace={"normal"}>{itm.name}</Typography>
                        </MenuItem></NavLink>
                )}
                {binaryItems.length > 0 && <NavLink key={"accessories"}
                                            to={encodeURIComponent(optCateg) + "/" + encodeURIComponent("accessories")}
                                            className={({isActive}) => isActive ? "active" : ""}>
                    <MenuItem sx={{paddingTop: "8px", paddingBottom: "8px"}}>
                        {allItemsCompleted(binaryItems) && <CheckIcon sx={{color: "rgba(0, 0, 0, 0.6)"}}/>}
                        <Typography ml={3} variant={"subtitle1"}
                                    whiteSpace={"normal"}>More Options</Typography>

                    </MenuItem>
                </NavLink>}
            </MenuList>
        </AccordionDetails>
    </Accordion>
}
export const CategoryListNavigation: React.FC<ICategoryProps> = (props) => {
    const {category, configurator, wishlistManager} = props;

    const optCategs = category.optCategs;

    const [openCategories, setOpenCategories] = useState<Record<string, boolean>>({});
    const availableChoices = configurator.getAllChoices();
    const itemsBySelectionId = groupBy(availableChoices, ch => ch.items[0].item.id);

    useEffect(() => {
        if (optCategs === undefined) return;
        const openCategs: Record<string, boolean> = {};
        optCategs.forEach(oc => openCategs[oc] = false);
        setOpenCategories(openCategs);
    }, [optCategs]);

    const avail = configurator.getOptionAvailability(availableChoices);

    const items = configurator.getOptionItems();

    if (optCategs === undefined) throw new Error('Unable to use CategoryList with no optCategs');

    const priceSource = (choice: IOptionItemAvailableChoice) => {
        return configurator.getPriceDeltaForChoice({ choice: choice, quantity: 1, price: null });
    }

    const visibleItems = items.filter(itm => optCategs.includes(
            itm.categories["Category"]) && itm.categories["DesignCenter"] === "yes" &&
        isSelectionItemAvailable(itemsBySelectionId[itm.id], avail, priceSource)
    );
    const visibleAttributeItems = visibleItems.filter(itm => itm.type === "Attribute" || itm.type === "Binary");
    const optCategGroups = getItemsGroupedByCategory(visibleAttributeItems);

    const navigate = useNavigate();
    const onExpandCategory = (isExpanded: boolean, optCateg: string) => {
    
        setOpenCategories(old => {
            let openCategs = {...old};
            openCategs[optCateg] = isExpanded;
            return openCategs;
        });

        //select first optsel
        if (isExpanded) {
            const items = optCategGroups[optCateg];
            if (items.length > 0) {
                //attribute options are shown first
                const firstAttribute = items.find(i => i.type === "Attribute");
                const optSelUri = firstAttribute ? firstAttribute.id : "accessories";
                navigate(encodeURIComponent(optCateg) + "/" + encodeURIComponent(optSelUri));
            }
        }
    };

    return <CategorySection>
        <Typography>{category.name}</Typography>
        {Object.keys(optCategGroups).map(optCateg =>
           <CategoryListCategory key={optCateg} optCateg={optCateg}
                                 isExpanded={openCategories[optCateg] ?? false}
                                 onExpand={isExpanded => onExpandCategory(isExpanded, optCateg)}
                                 items={optCategGroups[optCateg]}
                                 isItemComplete={(item) => wishlistManager.isSelected(item)}
                                 complete={optCategGroups[optCateg].find(itm => !wishlistManager.isSelected(itm)) !== undefined}
            />
        )}
    </CategorySection>
}