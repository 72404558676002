import {Box, Button, CardActions, Switch} from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";

export const MaterialCardActionsBinary: React.FC<{ onSelect: (isSelected: boolean) => void, isSelected: boolean, isFavorite?: boolean, onSetFavorite?: (value: boolean) => void }> = (props) => {
    const {onSelect, isSelected, onSetFavorite, isFavorite} = props;
    return <CardActions disableSpacing={false} sx={{
        display: 'flex',
        justifyContent: "center",
        flexDirection: 'column',
        height: "68px"
    }}>


        <Box sx={{flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: "center", display: "flex"}}>
            <Switch
                checked={isSelected}
                onChange={(ev) => {
                    onSelect(ev.target.checked)
                }}/>
            { isSelected ? "Selected" : "Not Selected" }
            {isFavorite !== undefined && <Button onClick={() => onSetFavorite && onSetFavorite(!isFavorite)}
                                                 sx={{
                                                     minWidth: "90px",
                                                     color: "gray",
                                                     borderColor: "gray",
                                                     paddingLeft: "10px",
                                                     paddingRight: "10px"
                                                 }} variant={"outlined"}>
                {isFavorite === false && <FavoriteBorderIcon/>}
                {isFavorite === true && <FavoriteIcon/>}
            </Button>
            }

        </Box>

    </CardActions>

}