import {AppBar, Box, Divider, IconButton, Toolbar, Typography} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import styled from "@emotion/styled";
import {useNavigate} from "react-router-dom";


const ConfiguratorMainDiv = styled.div`
display:flex;
  flex-direction: row;
  background-color: #f5f5f5;
  .selection {
    flex: 1 0 1000px;
   
    
  }
  .skuWorld {
    margin: 20px;
    flex: 1 0;
  }
`



const MainDiv = styled.div`


`
const OuterMainDiv = styled.div`
  display: flex;
  flex-direction:row;
  .stepsnav {
    flex: 0 0 144px;
    background-color: #3d6b77;
  }
  .mainmain {
    height: 100vh;
    flex-grow:1;
    display: flex;
    background-color: #f5f5f5;
    flex-direction: column;
  }
  .appbar {
    flex: 0 0;
  }
  .main {
    flex: 1 1;
    overflow:hidden;

  }
`

export const MainLayout: React.FC<{ appBarContent?: React.ReactNode, children?: React.ReactNode }> = (props) => {
    const navigate = useNavigate();
    return (<OuterMainDiv>

            {props.appBarContent && <div className={"stepsnav"}>
                {props.appBarContent}

            </div>
            }
            <div className={'mainmain'}>
            <div className={"appbar"}>
                <AppBar position={"sticky"} sx={{backgroundColor: "white"}}>
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            onClick={() => navigate('/')}
                            aria-label="menu"
                            sx={{
                                mr: 2,
                                position: "absolute"
                            }} // position absolute because we want the title text to be centered
                        >
                        </IconButton>
                        <Box sx={{justifyContent: "center", flex: "1 0 0", display: "flex"}}>
                            <img src={"/branding/logo.png"}  />

                        </Box>
                    </Toolbar>

                </AppBar>
            </div>
            <ConfiguratorMainDiv className={"main"}>

                {props.children}
            </ConfiguratorMainDiv>
            <Box sx={{backgroundColor: "#f5f5f5", display: 'flex', flexDirection: 'column'}} height={72} ml={4} mr={4}>
                <Divider></Divider>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow:1}}>
                <Typography textAlign={"center"}><a href={"https://www.bimaire.com/ods-disclaimer"} target={"_blank"} style={{color: '#00000099', textDecorationLine: 'none'}}>Disclaimer</a></Typography>
                </Box>
                </Box>
        </div>
        </OuterMainDiv>
    )
}
