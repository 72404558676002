import groupBy from "@bimaire/optionslib/build/utils/groupBy";
import {MUISelectionChoicesMultipleStyles} from "./MUISelectionChoicesMultipleStyles";
import {MUISelectionChoicesNoStyles} from "./MUISelectionChoicesNoStyles";
import {IMUISelectionChoicesProps} from "./types";

/**
 * Component that renders the choices for a "normal" option, i.e. not a package.
 * @param props
 * @constructor
 */
export const MUISelectionNormalItemChoices: React.FC<IMUISelectionChoicesProps> = (props) => {
    const {choices, ...restProps} = props;
    const choicesByValue = groupBy(choices, ch => ch.items[1].item.id);
    const allChoiceGroups = Object.values(choicesByValue);
    const choiceGroupsWithStyles = allChoiceGroups.filter(ch => ch.length > 1);
    const choicesWithNoStyles = allChoiceGroups.filter(ch => ch.length === 1);
    return <>
        {(choiceGroupsWithStyles.length > 0 && choicesWithNoStyles.length > 0 ? allChoiceGroups : choiceGroupsWithStyles).map(chg => <MUISelectionChoicesMultipleStyles
            key={chg[0].items[1].item.id} {...restProps} choices={chg}/>)}
        {choicesWithNoStyles.length > 0 && choiceGroupsWithStyles.length === 0 &&
        <MUISelectionChoicesNoStyles {...restProps} choices={choicesWithNoStyles.flat()}/>}
    </>

}