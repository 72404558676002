import {useParams} from "react-router-dom";
import styled from "@emotion/styled";

import {useState} from "react";
import {
    IConfiguratorProxy,
    IOptionChoiceAvailabilityMap,
    IOptionItem,
    IOptionItemAvailabilityMap,
    IOptionItemAvailableChoice, IOptionItemChoice
} from "@bimaire/optionslib";
import {MUISelectionChoices} from "./Choices/MUISelectionChoices";
import { WishlistManager } from "@bimaire/optionslib/build/api/WishlistManager";
import {IImageSource} from "@bimaire/optionslib/build/api/customerportal-client";
import { ISkuElement } from "@bimaire/optionslib/build/api/skuworld-client";
import {SkuDetailsDialog} from "@bimaire/react-lib";
import {IPackageConfig} from "../Configurator/ConfiguratorLoader";
import { MUIOptItemCardMultipleHeader } from "./Choices/MUIOptItemCardMultipleHeader";
import { IPricingConfig } from "../../App";

const CategoryOptItemDiv = styled.div`
    display: flex;
    flex-direction: row;
  flex-wrap:wrap;
    gap:10px;
  
`

const MUICategoryOptItemDiv = styled.div`
    padding:16px;
  display:flex;
  &.styles {
    flex-direction: column;
  }
  &.noStyles {
    flex-direction:row;
  }
  flex-wrap:wrap;
  gap:10px;
`

;

const MaterialCardDiv = styled.div`
  display:flex;
  flex-direction: column;
  width: 215px;
  header {
    display: flex;
    flex-direction:row;
    align-items:center;
    padding: 8px 8px 0px 16px;
    align-self: stretch;
    margin: 0;
    height:56px;
    .content {
      display:flex;
      flex-direction:column;
      align-items:flex-start;
      padding:0;
      flex: 1 0;
      > * { margin: 0;}

    }
    
  }
  
  .favorite {
  }
  .actions {
    display: flex; flex-direction: row; align-items: center; padding:16px;
    .select {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px;

      /* Info/Dark */

      border: 1px solid #0B79D0;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
`


export function isMenuItemAvailable(availability: IOptionChoiceAvailabilityMap["availability"], item: IOptionItem) {
    if (item.name.includes("Please Select")) return false;
    return true;
    //return availability.get(item)?.isVisible && availability.get(item)?.isEnabled;

}

export function isChoiceAvailable(availability: IOptionChoiceAvailabilityMap["availability"], choice: IOptionItemAvailableChoice) {

    return availability.get(choice)?.isVisible && availability.get(choice)?.isEnabled;

}
/*function isAvailable(avail: IOptionItemAvailabilityMap, item: IOptionItem) {
    const a = avail.availability;

    const availInfo = a.get(item);
    if (availInfo === undefined) return true;
    return availInfo.isVisible && availInfo.isEnabled;

}*/

export interface ICategoryOptItemProps {

    paramName: string;
    packageConfig: IPackageConfig;
    configurator: IConfiguratorProxy;
    imageSource: IImageSource;
    onSelect: (choice: IOptionItemChoice) => void;
    wishlistManager: WishlistManager;
    pricing?: IPricingConfig;
}
const MUICategoryOptItem : React.FC<ICategoryOptItemProps> = (props) => {
    const {configurator, paramName, onSelect, wishlistManager, packageConfig, pricing} = props;

    const [shownSkuDetails, setShownSkuDetails] = useState<ISkuElement | null>();

    const optSel = useParams()[paramName] as string;

    const wishlistFavorites = wishlistManager.wishlistOptions.filter(wo => wo.selection === optSel);

    const items = configurator.getOptionItems();
    const choices = configurator.getChoices();
    const item = items.find(itm => itm.id === optSel);
    const allChoices = configurator.getAllChoices();
    const choicesForOptSel = allChoices.filter(ch => ch.items[0].item === item);
    const avail = configurator.getOptionAvailability(choicesForOptSel);

    if (item === undefined) throw new Error(`OptSel ${optSel} not found!`);

    const priceSource = (choice: IOptionItemAvailableChoice) => {

        try {
            return configurator.getPriceDeltaForChoice({choice: choice, quantity: 1, price: null});
        } catch (e) {
            debugger;
            return 0;
        }
    }

    const visibleChoicesForOptSel = choicesForOptSel.filter(ch =>
      isChoiceAvailable(avail.availability, ch) && 
      ch.items.find(itm => itm.item.name.includes("Please Select")) === undefined &&
      priceSource(ch) !== null
    );

    if (optSel === undefined) throw new Error('optsel missing');

    if (item.menu === null) return null;

    const onValueFavoriteSelect = (choice: IOptionItemAvailableChoice, val:boolean) => {
        wishlistManager.updateWishlistOption(choice, val ? "Favorite" : "None");
    }
    const onValueSelect = (choice: IOptionItemChoice) => {

        onSelect(choice);
    }
    // Do these have children? If they don't, then we lay them all in the same flex row. Otherwise we group the styles/colors by OptVals.
    let anyHasChildren = false;
    if (item.menu !== null) {
        anyHasChildren = item.menu.items.find(itm => isMenuItemAvailable(avail.availability, itm) && itm.menu !== null && itm.menu.items.length > 0) !== undefined;
    }
    const currentChoice = choices.find(ch => ch.choice.items[0].item === item);
    return (<MUICategoryOptItemDiv className={anyHasChildren ? "styles" : "noStyles"} key={item.id}>
        {shownSkuDetails && <SkuDetailsDialog onClose={() => setShownSkuDetails(null)} sku={shownSkuDetails} /> }
        <MUIOptItemCardMultipleHeader name={item.name} backgroundColor="#ffffff" />
        <MUISelectionChoices choices={visibleChoicesForOptSel}
                             currentChoice={currentChoice ?? null}
                             key={item.id}
                             packageConfig={packageConfig}
                             pricing={pricing}
                             priceSource={priceSource}
                             wishlistFavorites={wishlistFavorites}
                             onSetFavorite={onValueFavoriteSelect}
                             onSelect={onValueSelect}
                             onShowSkuDetails={setShownSkuDetails}
        />
        { /* <MUICategoryOptItemChildrenNoChildren
        key={item.id}
        priceSource={priceSource}
        wishlistFavorites={wishlistFavorites}
        onSetFavorite={onValueFavoriteSelect}
            onShowSkuDetails={setShownSkuDetails} item={item} onSelect={onValueSelect}
                                              choice={choices.find(ch => ch.choice.items[0].item === item) ?? null}
                                              availability={avail.availability}/>
*/ }
    </MUICategoryOptItemDiv>)

}

export default MUICategoryOptItem;