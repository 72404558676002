import WishlistClient, {IWishlist, IWishlistOption} from "@bimaire/optionslib/build/api/wishlist-client";
import { IWishlistEdit } from "@bimaire/optionslib/build/api/WishlistManager";
import {useEffect, useState} from "react";

export function useWishlist(wishlistClient: WishlistClient): [IWishlistOption[] | null, (wishlistOptions: IWishlistEdit[]) => void, IWishlistOption[] | null] {
    const [wishlist, setWishList] = useState<IWishlist | null>(null);
    const [serverWishlistOptions, setServerWishlistOptions] = useState<IWishlistOption[] | null>(null);
    const [userDefinedWishlistOptions, setUserDefinedWishlistOptions] = useState<IWishlistOption[] | null>(null);

    useEffect(() => {

        wishlistClient.getOrCreateDefaultWishlist().then(wl => setWishList(wl));
    }, [wishlistClient]);

    useEffect(() => {

        if (wishlist === null) return;
        wishlistClient.getWishlistOptions(wishlist).then(wlo => setServerWishlistOptions(wlo));
    }, [wishlist, wishlistClient]);

    async function updateWishlist(editOperations: IWishlistEdit[]) {
        if (wishlist === null) {
            throw new Error('Wishlist not yet available');
        }
        wishlistClient.updateWishlist(wishlist, editOperations).then(respJson => {
            setUserDefinedWishlistOptions(respJson);
        })
    }

    return [userDefinedWishlistOptions || serverWishlistOptions, updateWishlist, serverWishlistOptions];

}