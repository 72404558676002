import styled from "@emotion/styled";
import {NavLink} from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { IStepWithProgress } from "../Dashboard/Dashboard";
import { Typography } from "@mui/material";

export const StepsDiv = styled.div`

    display:flex;
  flex-direction:column;
  align-items:center;
  width: 200px;
  
  .homebutton {
    height: 84px;
    display:flex;
    justify-content: center;
    align-items: center;
    a {
      color: white;
      text-decoration: none;
    }
    svg {
      width: 35px;
      height: 35px;
    }
  }
  .tabitem {
    
    color: white;
    text-align:left;
    height: 60px;
    width:100%;
    text-decoration: none;
    display:flex;
    flex-direction:column;
    align-items: left;
    justify-content: center;
    text-transform: uppercase;
    font-size: 14px;
    margin-left: 16px;
    padding-left: 16px;
  }
  .tabitem.active {
    background-color:white;
    color: #3d6b77;
  }
  .tabitem.active:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
  .tabitem:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .default {
    color: #BDBDBD;
  }
  .started {
    color: #FFB547;
  }
  .completed {
    color: #4CAF50;
  }
`

export interface IMainMenuItem {
    name: string;
    href: string;
}

const MainMenu: React.FC<{ items: IMainMenuItem[], progress?: IStepWithProgress[]}> = (props) => {
    const {items, progress} = props;

    const getProgress = (name: string) => {
      if (progress === undefined) return null;
      const prog = progress.find(p => p.name === name);
      if (prog === undefined) return null;

      if (prog.items === 0) return 100;
      return Math.round(prog.completedItems / prog.items * 100);
    }

    const getProgressColor = (name: string) => {
      const prog = getProgress(name);
      if (prog) {
        return prog < 100 ? 'started' : 'completed';
      }
      return 'default';
    }

    return <StepsDiv>
        <div className={"homebutton"}><NavLink to={"/"}><HomeIcon/></NavLink></div>
        {items.map((categ, idx) => 
          <NavLink key={idx} className={"tabitem"} to={categ.href}>
            <Typography variant="button">{categ.name}</Typography>
            {getProgress(categ.name) !== null && <Typography className={getProgressColor(categ.name)} variant="body2" sx={{textTransform:'none'}}>{getProgress(categ.name) + '% Completed'}</Typography>}
          </NavLink>)}

    </StepsDiv>;

}
export default MainMenu;