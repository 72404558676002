import styled from "@emotion/styled";

const ContentContainerDiv = styled.div`
  width: 100%;
  height: 100%;
overflow:hidden;

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
`
/**
 * Used to display content for the main landing page of a option category.
 * @param content
 * @constructor
 */
export const CategoryContent: React.FC<{ content: string | undefined }> = ({content}) => {
    if (content === undefined) return null;

    return <ContentContainerDiv>
        <iframe key={content} src={content}/>
    </ContentContainerDiv>;
}