import {IOptionItemAvailableChoice} from "@bimaire/optionslib";
import {OptValCards} from "../OptValCards";
import {IMUISelectionChoicesProps} from "./types";
import {MaterialCardRow} from "./MaterialCardRow";

function getNameForSingleStyleChoice(choice: IOptionItemAvailableChoice) {
    return choice.items.slice(1).map(chItem => chItem.item.name).join(" / ");
}

/**
 * This component is used to render values that do not have styles/colors, or have just one.
 * In that case, they are all under the same "header" as their individual cards - no grouping by level 1 id.
 * @param props
 * @constructor
 */
export const MUISelectionChoicesNoStyles: React.FC<IMUISelectionChoicesProps> = (props) => {
    return <MaterialCardRow>
        <OptValCards {...props} enableIncludedBadge={true} nameSource={getNameForSingleStyleChoice}/>
    </MaterialCardRow>;
}