import {useEffect, useRef, useState} from "react";
import {Routes, Route, useNavigate} from "react-router-dom";


type SuccessfulLogin = {
    success:true;
    token:string;
}
type FailedLogin = {
    success:false;
    reason: string;
}
type ILoginResult = SuccessfulLogin | FailedLogin;

export interface ILoginProps {
    onLogin(userName:string, password:string) : void;
    onLoginWithCustomerPortalToken(encryptedToken:string) : void;
}

const CustomerPortalLogin: React.FC<{onLoginWithCustomerPortalToken: (tk:string) => void}> = ({onLoginWithCustomerPortalToken}) => {
    const hash = document.location.hash;
    const nav = useNavigate();
    useEffect(() => {
        onLoginWithCustomerPortalToken(hash.substring(1));

        }, [hash]);
    return <div>Logging in...</div>;
}

const Login: React.FC<ILoginProps> = ({onLogin, onLoginWithCustomerPortalToken}) => {

    const usernameElem = useRef<HTMLInputElement>(null);
    const passwordElem = useRef<HTMLInputElement>(null);

    const onSubmit = (ev: React.MouseEvent) => {
        ev.preventDefault();
        onLogin(usernameElem.current!.value, passwordElem.current!.value);
        return false;
    }
    return <Routes>
        <Route index element={
            <form>
                <label>Username: <input type={"text"} ref={usernameElem}/></label><br/>
                <label>Password: <input type={"password"} ref={passwordElem}/></label><br/>
                <button type={"submit"} onClick={onSubmit}>Login</button>
            </form>
        } />
        <Route path={"/cplogin"} element={
            <CustomerPortalLogin onLoginWithCustomerPortalToken={onLoginWithCustomerPortalToken}></CustomerPortalLogin>
        }/>


    </Routes>
}

export default Login;