import {IOptionItemAvailableChoice} from "@bimaire/optionslib";
import {ChoiceCard} from "./Choices/Cards/ChoiceCard";
import {MaterialCardActions} from "./MaterialCardActions";
import {getNameForChoice} from "./Choices/utils";
import {IMUISelectionBaseChoiceProps, IMUISelectionChoicesProps, SelectionType} from "./Choices/types";
import { priceToString } from "../Summary/Summary";
import { SkuLoader } from "@bimaire/react-lib";
import { findWishlist } from "@bimaire/optionslib/build/api/wishlist-client";
import { IPricingConfig } from "../../App";


export interface IOptValCardsProps extends IMUISelectionChoicesProps {
    /**
     * If true, then we show the "included" badge if the option is available for free for the homebuyer
     */
    enableIncludedBadge?: boolean,
    nameSource?: (choice: IOptionItemAvailableChoice) => string
}
export interface IOptValCardProps extends IMUISelectionBaseChoiceProps {
    availableChoice: IOptionItemAvailableChoice;
    enableIncludedBadge?: boolean,
    nameSource?: (choice: IOptionItemAvailableChoice) => string;
}
export const OptValCard: React.FC<IOptValCardProps> = (props) => {
    const {
        wishlistFavorites,
        onSetFavorite,
        availableChoice,
        priceSource,
        nameSource,
        enableIncludedBadge,
        currentChoice,
        onSelect,
        onShowSkuDetails,
        pricing

    } = props;
    const price = priceSource(availableChoice);
    const isFavorite = findWishlist(wishlistFavorites, "Favorite", availableChoice);
    const isUserChoice = findWishlist(wishlistFavorites, "Choice", availableChoice);
    let selectionType: SelectionType = "no";
    if (currentChoice?.choice === availableChoice) {
        selectionType = isUserChoice ? "user" : "default";
    }

    const actions = <MaterialCardActions isSelected={selectionType}
                                         onSelect={() => onSelect({price: null, quantity: 1, choice: availableChoice})}
                                         onSetFavorite={(val) => onSetFavorite(availableChoice, val)}
                                         isFavorite={isFavorite}/>;
    return <SkuLoader key={availableChoice.items.map(itm => itm.item.id).join(",")} choice={availableChoice}>
        {({skuDetails}) => <ChoiceCard
            actions={actions}
            skuDetails={skuDetails}
            subheader={priceToString(price, pricing)}
            name={(nameSource ?? getNameForChoice)(availableChoice)}
            onShowSkuDetails={onShowSkuDetails}
            showIncluded={!!enableIncludedBadge && !!pricing?.show && price === 0}
            key={availableChoice.items.map(itm => itm.item.id).join("/")}

        />
        }
    </SkuLoader>

}
export const OptValCards: React.FC<IOptValCardsProps> = (props) => {

    const {choices} = props;

    return <>
        {choices.map((availableChoice, idx) => <OptValCard {...props} key={idx} availableChoice={availableChoice}/>)}
    </>


}