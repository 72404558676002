import {Button, CardActions, CardHeader, IconButton} from "@mui/material";
import {priceToString} from "../../Summary/Summary";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { IPricingConfig } from "../../../App";

export interface IMUIOptItemCardSingleHeaderProps {
    onSelect: () => void;
    price: number | null;
    name: string;
    isSelected: boolean;
    onSetFavorite: (val: boolean) => void;
    isFavorite: boolean;
    pricing?: IPricingConfig;
}

export const MUIOptItemCardSingleHeader: React.FC<IMUIOptItemCardSingleHeaderProps> = (props) => {
    const {onSelect, price, name, isSelected, onSetFavorite, isFavorite, pricing} = props;
    return (<CardHeader titleTypographyProps={{style: {fontSize: "18px"}}}

                        title={name}
                        subheader={priceToString(price, pricing)}
                        action={<CardActions disableSpacing={false} sx={{width: "222px"}}>
                            <IconButton sx={{flex: "0 0"}}
                                        onClick={onSetFavorite ? () => onSetFavorite(!isFavorite) : undefined}>
                                {isFavorite === false && <FavoriteBorderIcon/>}
                                {isFavorite === true && <FavoriteIcon/>}
                            </IconButton>
                            <Button sx={{flex: "1 1"}} size={"small"}
                                    variant={isSelected ? "contained" : "outlined"}
                                    onClick={onSelect ? () => onSelect() : undefined}>{isSelected ? "Selected" : "Select"}</Button>


                        </CardActions>}

    >

    </CardHeader>)
}