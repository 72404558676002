import {IConfiguratorProps} from "../Configurator/Configurator";
import MainMenu from "../MainMenu/MainMenu";
import CategoryList from "../Categories/CategoryList";
import Summary from "../Summary/Summary";
import {MainLayout} from "../Configurator/MainLayout";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import {useQuery} from "react-query";
import {IInspirationConfig} from "../../App";
import {Box, Button, Card, CardActions, CardHeader, CardMedia} from "@mui/material";
import { InspirationModelDetailsRoute } from "./InspirationModelDetails";
import { IInspirationModelResponse } from "@bimaire/optionslib-kova/build/api/dcportal-apiclient";

const fetchInspiration = (apiEndpoint:string) => {
    return fetch(apiEndpoint + "inspiration/models");
}

export interface IInspirationCategoryProps {
    inspirationData: IInspirationModelResponse[];
    inspirationConfig: IInspirationConfig;
}
export interface IInspirationModelCardProps {
    model: IInspirationModelResponse;
    onLearnMore: () => void;
};


const InspirationModelCard: React.FC<IInspirationModelCardProps> = (props) => {

    const { model, onLearnMore } = props;
    return <Card sx={{width: "300px", height: "445px"}}>
        <CardMedia sx={{width: "300px", height: "300px"}}>
            <div>Image</div>
        </CardMedia>
        <CardHeader sx={{paddingBottom:0}} titleTypographyProps={{fontSize: 16}} subheaderTypographyProps={{fontSize:14}} title={model.modelName} subheader={<>{model.communityName}<br/>Style Name</>} />

        <CardActions>
            <Button variant={"outlined"} sx={{width: '100%'}} onClick={onLearnMore}>Learn More</Button>
        </CardActions>

    </Card>
}
const InspirationCategory: React.FC<IInspirationCategoryProps> = (props) => {
    const { inspirationData, inspirationConfig } = props;
    const { categoryId } = useParams();
    const navigate = useNavigate();
    if (categoryId === undefined) throw new Error('invalid category id');
    const category = inspirationConfig.categories[parseInt(categoryId)];
    const allModels = inspirationData.filter(elem => elem.categories.includes(category.name));


    return <Box pt={4} pl={3} pr={3} sx={{display: 'flex', flexDirection: 'row'}}>
        { allModels.map((model, idx) => <InspirationModelCard model={model} onLearnMore={() => navigate(`${model.id}`)}/>) }
    </Box>



}

const InspirationMain: React.FC<IConfiguratorProps> = (props) => {

    const { config } = props;


    const inspirationData = useQuery('inspiration.data', () => props.customerPortalClient.getInspirationModels());


    const inspirationConfig = config.inspiration;
    const items = inspirationConfig.categories.map((cat, idx) => ({name: cat.name, href: `/inspiration/${idx}`}));


    const itemsWithWelcome = [{name: "Welcome", href: "/inspiration/"}, ...items];

    return <MainLayout appBarContent={<MainMenu items={itemsWithWelcome} />}>
        <div className={"selection"}>
            { inspirationData.isLoading && <div>Loading...</div> }
            {!inspirationData.isLoading && inspirationData.data !== undefined &&
                <Routes>
                    <Route index element={<iframe style={{width: '100%', height: '100%'}} src={inspirationConfig.welcomeUrl} />
                    }/>
                    <Route path={":categoryId"} element={<InspirationCategory inspirationData={inspirationData.data} inspirationConfig={inspirationConfig} />}/>
                    <Route path={":categoryId/:modelId"} element={<InspirationModelDetailsRoute inspirationData={inspirationData.data} inspirationConfig={inspirationConfig} />} />

                </Routes>
            }
        </div>
    </MainLayout>
}
export default InspirationMain;