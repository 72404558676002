import {priceToString} from "../../Summary/Summary";
import {MUIOptItemCardMultipleHeader} from "./MUIOptItemCardMultipleHeader";
import {CardContent} from "@mui/material";
import {OptValCards} from "../OptValCards";
import {IMUISelectionChoicesProps} from "./types";
import {MaterialCardRow} from "./MaterialCardRow";

/**
 * This component is used if a single value has multiple styles/colors.
 * i.e. we have the value name as the header, and then the style/color combinations as their own cards.
 * @param props
 * @constructor
 */
export const MUISelectionChoicesMultipleStyles: React.FC<IMUISelectionChoicesProps> = (props) => {

    const {choices, priceSource, pricing} = props;

    const firstPrice = priceSource(choices[0]);
    const hasSamePrice = choices.find(sc => priceSource(sc) !== firstPrice) === undefined;
    const valueItemName = choices[0].items[1].item.name;

    const itemName = (hasSamePrice && pricing !== undefined && pricing.show) ? `${valueItemName} - ${priceToString(firstPrice, pricing, "Included")}` : valueItemName;
    return <div>
        <MUIOptItemCardMultipleHeader backgroundColor={"#eeeeee"}
                                      name={itemName}/>
        <CardContent sx={{pt: 4, '&:last-child': {pb: "16px"}}}>
            <MaterialCardRow>
                <OptValCards {...props} enableIncludedBadge={!hasSamePrice}/>
            </MaterialCardRow>
        </CardContent>

    </div>
}