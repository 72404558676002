/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import {Route, Routes} from "react-router-dom";
import {IConfiguratorProxy, IOptionItemAvailableChoice, IOptionItemChoice} from "@bimaire/optionslib";
import MUICategoryOptItem from "./MUICategoryOptItem";
import Accessories from "../Accessories/Accessories";
import {ICategoryConfig, IPricingConfig} from "../../App";
import {CategoryContent} from "./CategoryContent";
import {CategoryListNavigation} from "./CategoryListNavigation";
import {WishlistManager} from "@bimaire/optionslib/build/api/WishlistManager";
import {IImageSource} from "@bimaire/optionslib/build/api/customerportal-client";
import {IPackageConfig} from "../Configurator/ConfiguratorLoader";




const CategoryListDiv = styled.div`
display:flex;
  height:100%;
  flex-direction: row;
  a { display:block; text-decoration: none;}
  .active h6 { color: inherit; }
  .active {  background-color:white; color: #5A9EB0; }
  h6 { color: rgba(0, 0, 0, 0.6); }
  
`
const CategoryContentSection = styled.section`
  background-color:white;
flex: 1 1;
  overflow-x:auto;
`
export interface ICategoryProps {
    configurator: IConfiguratorProxy;
    wishlistManager:WishlistManager;
    imageSource: IImageSource;
    onSelect: (choice: IOptionItemChoice) => void;
    category: ICategoryConfig;
    packageConfig: IPackageConfig;
    pricing?: IPricingConfig;
}
const CategoryList: React.FC<ICategoryProps> = (props) => {
    const {configurator, imageSource, onSelect, wishlistManager, category, packageConfig, pricing} = props;

    const priceSource = (choice: IOptionItemAvailableChoice) => {

        return configurator.getPriceDeltaForChoice({ choice: choice, quantity: 1, price: null });
    }
    return <CategoryListDiv>


        { category.optCategs && <CategoryListNavigation {...props} /> }

        <CategoryContentSection>
        <Routes>
            <Route path={"/"}  element={<CategoryContent content={category.content} />} />
            <Route path={":optCateg/accessories"} element={<Accessories
                category={category}
                imageSource={imageSource}
                priceSource={priceSource}
                pricing={pricing}
                onSelect={onSelect}
                wishlistManager={wishlistManager}
                configurator={configurator} />} />
            <Route path={":optCateg/:optSel"} element={<MUICategoryOptItem imageSource={imageSource}
                                                                           onSelect={onSelect}
                                                                           packageConfig={packageConfig}
                                                                           pricing={pricing}
                                                                           wishlistManager={wishlistManager}
                                                                           configurator={configurator}
                                                                           paramName={"optSel"} />}>

            </Route>
        </Routes>
        </CategoryContentSection>
    </CategoryListDiv>
}



export default CategoryList;