import {IOptionItemAvailableChoice} from "@bimaire/optionslib";
import {MaximumOptionLength} from "../../../Features";

export function truncateName(name:string) {
    if (name.length > MaximumOptionLength) {
        let truncatedName = name.substring(0, MaximumOptionLength);
        truncatedName += "...";
        return truncatedName;
    }
    return name;
}
export function getNameForChoice(choice: IOptionItemAvailableChoice) {
    const choiceItems = choice.items;

    if (choiceItems.length === 1) return choiceItems[0].item.name;
    if (choiceItems.length === 2) return choiceItems[1].item.name;
    if (choiceItems.length > 2) return choiceItems.slice(2).map(c => c.item.name).join(" / ");
    return "-";
}