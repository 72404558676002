import {ICategoryConfig, IConfig, IPricingConfig} from "../../App";
import styled from "@emotion/styled";
import {Accordion, CardHeader, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useState} from "react";
import groupBy from "@bimaire/optionslib/build/utils/groupBy";
import {IConfiguratorProxy, IOptionItem, IOptionItemAvailableChoice} from "@bimaire/optionslib";
import {isSelectionItemAvailable} from "../Categories/CategoryListNavigation";
import {SkuLoader, MaterialCardStandard, MaterialCardImage, MaterialCardImageContainer, SkuDetailsDialog} from "@bimaire/react-lib";
import {getItemFromWishlistItem2, IWishlistOption} from "@bimaire/optionslib/build/api/wishlist-client";
import {WishlistManager} from "@bimaire/optionslib/build/api/WishlistManager";
import { ISkuElement } from "@bimaire/optionslib/build/api/skuworld-client";
import {truncateName} from "../Categories/Choices/utils";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";


export interface ISummaryProps {
    configurator: IConfiguratorProxy;
    wishlistManager: WishlistManager;
    config: IConfig;
}
const SummaryDiv = styled.div`
    height: 100%;
  overflow-y: scroll;
  
  .category {
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-top: 24px;
  }
  
  .categoryItems {
    display:flex;
    margin: 16px 0px;
    gap:50px;
    flex-wrap: wrap;
   
  }
  padding: 32px;
box-sizing:border-box;
  .catgroup {
    display:flex;
    align-items: center;
    height: 32px;
    padding-left: 16px;
    font-weight: 500;
    background-color: #eeeeee;
  }
  .elements {
    margin-top: 16px;
    display: flex;
    gap: 16px;
    flex-direction: row;
   
  }

`


interface ISummaryCategoryProps {
    category: ICategoryConfig;
    pricing?: IPricingConfig;
    configurator: IConfiguratorProxy;
    wishlistManager: WishlistManager;
    onShowSku: (sku: ISkuElement) => void;
}
export interface ISummaryItemProps {
    item: IOptionItem;

    availableChoices: IOptionItemAvailableChoice[];
    wishlistManager: WishlistManager;
    onShowSku: (sku: ISkuElement) => void;
    priceSource: (wishlistOption: IWishlistOption) => number|null;
    pricing?: IPricingConfig;

}
const SummaryItemDiv = styled.div`
  display:flex;
  flex-direction:column;
  align-items: center;
  header {
    max-width:200px;
    height:32px;
    text-align: center;
  }
`
interface ISummaryItemElementProps {
    wishlistChoice: IOptionItemAvailableChoice;
    price: number|null;
    isSelected: boolean;
    onShowSku: (sku: ISkuElement) => void;
    pricing?: IPricingConfig;
}
const BadgeDiv = styled.div`
text-align:center;
  width: 100%;
  position: absolute;
  bottom:0;
`
export const Badge: React.FC<{color?: string, children?: React.ReactNode}> = ({color, children}) => {
    return <BadgeDiv style={{backgroundColor: color ?? "#7BC67E"}}>
        { children }
    </BadgeDiv>
}
export function priceToString(price:number|null, pricing?: IPricingConfig, zeroString?:string) {

    if (price !== null && pricing !== undefined && pricing.ranges !== undefined){
        for (const range of pricing.ranges){
            if (price >= range.min && price <= range.max){
                return range.name;
            }
        }
        //no range found
        return "";
    }

    if (pricing === undefined || !pricing.show) return "";

    if (price === 0) return zeroString ?? "$0";

    return price !== null ? `$${price}` : "-";
}
const SummaryItemElement: React.FC<ISummaryItemElementProps> = props => {
    const {wishlistChoice, onShowSku, price, isSelected, pricing} = props;
    const choiceNameComps = wishlistChoice.items.slice(1).map(item => item.item.name);
    return <MaterialCardStandard name={truncateName(choiceNameComps.join(" / "))} subheader={priceToString(price, pricing)} >
        <SkuLoader choice={wishlistChoice}>
            {({skuDetails}) => <div style={{position: "relative"}}><MaterialCardImageContainer>
                {skuDetails && skuDetails.skus.length > 0 &&
                <MaterialCardImage imageUrl={skuDetails.skus[0].mainImage}
                                   onClick={() => onShowSku(skuDetails.skus[0])}/>

                }
                {skuDetails === null && <MaterialCardImage imageUrl={"/notavailable.png"}/>}
                { isSelected && <Badge>Selected</Badge>}
                { !isSelected && <Badge color={'#F88078'}>Favorite</Badge>}

            </MaterialCardImageContainer>
            </div>
            }


        </SkuLoader>

    </MaterialCardStandard>
}

const SummaryItem: React.FC<ISummaryItemProps> = (props) => {
    const { item, wishlistManager, onShowSku, priceSource, availableChoices, pricing } = props;

    const wochoice = wishlistManager.wishlistOptions.find(wo => wo.selection === item.id && wo.type === "Choice");

    const wofavorites = wishlistManager.wishlistOptions.filter(wo => wo.selection === item.id && wo.type === "Favorite");



    if (wochoice === undefined && wofavorites.length === 0) {
        return null;
    }
    return <SummaryItemDiv>
        <header>
            <Typography variant={"body1"}>{ item.name }</Typography>
        </header>
        <div className={"elements"}>
        { wochoice && <SummaryItemElement wishlistChoice={getItemFromWishlistItem2(availableChoices, wochoice)!} price={priceSource(wochoice)} pricing={pricing} isSelected={true} onShowSku={onShowSku}/> }
        { wofavorites.map(wof => <SummaryItemElement price={priceSource(wof)} wishlistChoice={getItemFromWishlistItem2(availableChoices, wof)!} pricing={pricing} isSelected={false} onShowSku={onShowSku}/> ) }
        </div>
    </SummaryItemDiv>
}
const SummaryCategory: React.FC<ISummaryCategoryProps> = (props) => {
    const { configurator, wishlistManager, category, onShowSku, pricing } = props;
    const optCategs = category.optCategs;
    if (optCategs === undefined) throw new Error('Unable to render SummaryCateg in a non-option category!');

    const items = configurator.getOptionItems().filter(itm => optCategs.find(c =>
        c === itm.categories["Category"]) !== undefined && itm.categories["DesignCenter"] === "yes"
    );

    const availableChoices = configurator.getAllChoices();

    const availableChoicesByItemId = groupBy(availableChoices, ch => ch.items[0].item.id);
    const availability = configurator.getOptionAvailability(availableChoices);

    const priceSourceChoice = (choice: IOptionItemAvailableChoice) => {
        return configurator.getPriceDeltaForChoice({ choice: choice, quantity: 1, price: null });
    }

    const visibleItems = items.filter(item => isSelectionItemAvailable(availableChoicesByItemId[item.id], availability, priceSourceChoice));

    if (visibleItems.length === 0) return null;

    const itemsByCatGroup = groupBy(visibleItems, itm => itm.categories["CatGroup"]);
    const priceSource = (wishlistOption: IWishlistOption) => {
        const wishlistPathItems = getItemFromWishlistItem2(availableChoices, wishlistOption);
        if (wishlistPathItems === undefined) return null;
        try {
            return configurator.getPriceDeltaForChoice({choice: wishlistPathItems, price: null, quantity: 1});
        } catch (e) {
            debugger;
            return 0;
        }

    }

    return <section className={"category"}>
        <Accordion key={category.name}>
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{color: "white"}} />} sx={{backgroundColor: "#3D6B77", color: "white"}}>
                <CardHeader title={category.name}></CardHeader>
            </AccordionSummary>
            <AccordionDetails>
            { Object.keys(itemsByCatGroup).map(catGroup => <div>
                <Typography className={"catgroup"} variant={"body1"}>{catGroup}            </Typography>
                
                <div className={"categoryItems"}>
                
                { itemsByCatGroup[catGroup].map(item => <SummaryItem
                    priceSource={priceSource}
                    pricing={pricing}
                    item={item}
                    availableChoices={availableChoicesByItemId[item.id]}
                    wishlistManager={wishlistManager}
                    onShowSku={onShowSku} />)}
                    
                </div>
            </div>) }

            </AccordionDetails>
        </Accordion>

    </section>

}
const Summary: React.FC<ISummaryProps> = (props) => {
    const { configurator, wishlistManager, config } = props;
    const [shownSkuDetails, setShownSkuDetails] = useState<ISkuElement | null>();

    return <SummaryDiv>
        {shownSkuDetails && <SkuDetailsDialog onClose={() => setShownSkuDetails(null)} sku={shownSkuDetails} /> }

        { config.categories.filter(cat => cat.optCategs !== undefined).map(cat => <SummaryCategory onShowSku={setShownSkuDetails} category={cat} configurator={configurator} wishlistManager={wishlistManager} pricing={config.pricing} />) }
    </SummaryDiv>;
}


export default Summary;
